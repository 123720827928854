import styles from "../assets/index.module.css";
import React from "react";
import { Link } from "react-router-dom";

export const Home = () => {
  return (
    <>
      <Link to={"/events"} className={styles.btn}>
        Events
      </Link>
      <a
        className={styles.btn}
        href="https://discord.gg/tesla"
        target={"_blank"}
        rel={"noopener noreferrer"}
      >
        Join Tesla Discord
      </a>
      <a
        className={styles.btn}
        href={"https://discord.gg/dpDa3Kh"}
        target={"_blank"}
        rel={"noopener noreferrer"}
      >
        Join CT Discord
      </a>
      <a
        className={styles.btn}
        href="https://www.reddit.com/r/cybertruck"
        target={"_blank"}
        rel={"noopener noreferrer"}
      >
        r/cybertruck
      </a>
      <a
        className={styles.btn}
        href="https://www.cybertruckownersclub.com/"
        target={"_blank"}
        rel={"noopener noreferrer"}
      >
        Owners Club
      </a>
    </>
  );
};
