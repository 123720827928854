import React from "react";
import styles from "../assets/index.module.css";
import { Link } from "react-router-dom";

type Event = {
  name: string;
  date: Date;
};

const events: Event[] = [];

export const Events = () => {
  return (
    <div className={styles.card}>
      <h2>Events</h2>
      {events.length > 0 ? "events" : <h4>There are no events scheduled</h4>}
      <Link to={"/"} className={styles.btn + " " + styles.btnMarginTop}>
        Back
      </Link>
    </div>
  );
};
